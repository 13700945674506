html,
body,
#root,
.App {
  height: 100vh;
  width: 100vw;
}

.location-list {
  overflow: scroll;
}

.location-selector .location-list {
  height: 100vh;
}

.city-view,
.map {
  height: 100vh;
}
